@font-face {
  font-family: SimSun;
  panose-1: 2 1 6 0 3 1 1 1 1 1;
}
@font-face {
  font-family: "Cambria Math";
  panose-1: 2 4 5 3 5 4 6 3 2 4;
}
@font-face {
  font-family: Calibri;
  panose-1: 2 15 5 2 2 2 4 3 2 4;
}
@font-face {
  font-family: "Kruti Dev 010";
}
@font-face {
  font-family: "\@SimSun";
  panose-1: 2 1 6 0 3 1 1 1 1 1;
}
/* Style Definitions */
p.MsoNormal,
li.MsoNormal,
div.MsoNormal {
  margin-top: 0in;
  margin-right: 0in;
  margin-bottom: 10pt;
  margin-left: 0in;
  line-height: 115%;
  font-size: 11pt;
  font-family: "Calibri", sans-serif;
}
.MsoChpDefault {
  font-size: 10pt;
  font-family: "Calibri", sans-serif;
}
/* Page Definitions */
@page WordSection1 {
  size: 595.3pt 841.9pt;
  margin: 1in 1in 1in 1in;
}
div.WordSection1 {
  page: WordSection1;
}
div.WordSection1 * {
  text-align: center !important;
}
.heading {
    color: #ff0000;
    font-weight: bold;
}

.subHeading {
    color: #f05a5a;
}