.bookContainer {
  /* border: red solid 5px; */
  margin: 20px;
}

body {
  background-color: #f0ed93 !important;
}

/* .selectBtn {
  font-family: "Kruti Dev 010";
} */
